const Logo1 = require("./playStore.png");

const PlayStore = () => {
  return (
    <div>
      {" "}
      <a
        href="https://play.google.com/store/apps/details?id=in.dailybee.user"
        target="_blank"
        rel="noreferrer"
      >
        <div className="App-normal">
          <button>
            <img src={Logo1} alt="dailybee logo" width="auto" height="50" />
          </button>
        </div>
      </a>
    </div>
  );
};

export default PlayStore;
