import React, { useState, useEffect } from "react";
import { gql, useApolloClient } from "@apollo/client";
import "./App.css";
import Table from "./components/Table";
import { Col, Row } from "antd";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import PlayStore from "./Playstore.jsx";
import StarRating from "./StarRating";
const Logo = require("./logo12345.png");

const GET_ORDER_DETAILS_BY_ID = gql`
  query GetOrderDetailsByOrderId($orderId: String!, $billVisited: Boolean) {
    GetOrderDetailsByOrderId(orderId: $orderId, billVisited: $billVisited) {
      storeId
      orderId
      storeName
      meta {
        cA
      }
      storePhone
      customerName
      customerPhone
      orderTotalDiscount
      orderTotalAmount
      cartTotalAmount
      products {
        productId
        productName
        orderPrice
        orderQuantity
        total
        listingPrice
      }
    }
  }
`;

const OrderTable = () => {
  const { id } = useParams();

  const [displayData, setDisplayData] = useState([]);
  const [orderData, setOrderyData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setisError] = useState(false);
  const [metaData, setMetaData] = useState({});
  const client = useApolloClient();

  useEffect(() => {
    const getData = async () => {
      client
        .query({
          query: GET_ORDER_DETAILS_BY_ID,
          variables: {
            orderId: id,
            billVisited: true,
          },
        })
        .then((result) => {
          setIsLoading(result.loading);
          setDisplayData(result.data.GetOrderDetailsByOrderId.products);
          setOrderyData(result.data.GetOrderDetailsByOrderId);
          setMetaData(result.data.GetOrderDetailsByOrderId.meta);
        })
        .catch((error) => {
          setisError(true);
        });
    };
    getData();
  }, []);

  const column = [
    { key: 1, heading: "Product Name", value: "productName" },
    { key: 2, heading: "QTY", value: "orderQuantity" },
    { key: 3, heading: "MRP", value: "listingPrice" },
    { key: 4, heading: "Price", value: "orderPrice" },
    { key: 5, heading: "Total", value: "total" },
  ];
  const totalData = orderData.orderTotalAmount;
  const tableData1 = [
    {
      productName: "Total",
      MRP: "",
      orderPrice: "",
      orderQuantity: " ",
      discount: " ",
      total: totalData,
    },
  ];

  const finalData = displayData.concat(tableData1);
  let date1 = metaData?.cA ? new Date(metaData.cA).toLocaleString() : null;
  return (
    <>
      <meta charSet="UTF-8"></meta>
      {isError ? (
        <h1>Error in loading data. Please try again later.</h1>
      ) : (
        <>
          {isLoading ? (
            <>
              <div className="center">Data is loading..</div>
              <div className="loader"></div>
              <div></div>
            </>
          ) : (
            <>
              <div className="App">
                <div></div>
              </div>
              <div className="App-normal">
                <img src={Logo} alt="dailybee logo" width="auto" height="50" />{" "}
              </div>
              <div className="App-bold">
                <b>{orderData.storeName} </b>
              </div>
              <div className="App-normal">
                <b>{orderData.storePhone} </b>
              </div>
              <div className="App-right">
                <Row style={{ marginTop: 48 }}>
                  <Col span={8} offset={8}>
                    <div>OrderId # : {orderData.orderId || "NA"},</div>
                    <div>Order Date : {date1},</div>
                  </Col>
                </Row>
              </div>
              <div className="App-left">
                <Row>
                  <Col span={8}>
                    {/* <div> <b> Customer Details</b></div> */}
                    <div>Customer Name : {orderData.customerName || "NA"}</div>
                    <div>Customer Phone: {orderData.customerPhone || "NA"}</div>
                  </Col>
                </Row>
              </div>

              <div className="App-normal">
                <Table data={finalData} column={column} />
              </div>

              <div className="App-left">
                <Row>
                  <Col span={8}>
                    <b>
                      <div>
                        Total Savings on MRP : RS.{" "}
                        {Math.floor(orderData.orderTotalDiscount) || "NA"}
                      </div>
                    </b>
                    <b>
                      <div>
                        Thanks {orderData.customerName} for shopping with us!{" "}
                        <p>&#128591;</p>
                      </div>
                    </b>
                  </Col>
                </Row>
              </div>
              <div>
                <StarRating />
              </div>
              <div>
                <PlayStore />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default OrderTable;
